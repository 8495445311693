var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-date-picker",
    _vm._b(
      {
        attrs: {
          type: _vm.localType,
          "value-format": _vm.valueFormat,
          "range-separator": "至",
          "start-placeholder": _vm.startPlaceholder,
          "end-placeholder": _vm.endPlaceholder,
          "default-time": _vm.localDefaultTime,
          placeholder: _vm.placeholder,
          "picker-options": _vm.localPickerOptions
        },
        on: { change: _vm.change },
        model: {
          value: _vm.localDate,
          callback: function($$v) {
            _vm.localDate = $$v
          },
          expression: "localDate"
        }
      },
      "el-date-picker",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }