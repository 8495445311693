/**
 * system Created by HuZiqiang on 2021/1/13.
 * 身份证校验
 */
export function isIdentityId(identityId) {
  var patrn = /(^\d{15}$)|(^\d{17}(\d|X|x)$)/;//长度或格式校验
  //地区校验
  var aCity = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外"
  };
  // 出生日期验证
  var sBirthday = (
      identityId.substr(6, 4) +
      "-" +
      Number(identityId.substr(10, 2)) +
      "-" +
      Number(identityId.substr(12, 2))
    ).replace(/-/g, "/"),
    d = new Date(sBirthday)
  // 身份证号码校验 最后4位  包括最后一位的数字/字母X
  var sum = 0,
    weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
    codes = "10X98765432";
  for (var i = 0; i < identityId.length - 1; i++) {
    sum += identityId[i] * weights[i];
  }
  var last = codes[sum % 11]; //计算出来的最后一位身份证号码

  var errorMsg = '';
  if (!patrn.exec(identityId)) {
    errorMsg = "你输入的身份证长度或格式错误"
  } else if (Number(analyzeIDCard(identityId)) < 16) {
    errorMsg = "你输入的身份证未满16周岁"
  }else if (!aCity[parseInt(identityId.substr(0, 2))]) {
    errorMsg = "你的身份证地区非法"
  } else if (sBirthday != d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()) { errorMsg = "身份证上的出生日期非法" } else if (identityId[identityId.length - 1] != last) {
    errorMsg = "你输入的身份证号非法"
  }
  return errorMsg;
}
export function analyzeIDCard (IDCard){
  var age = 0,yearBirth,monthBirth,dayBirth;
  //获取用户身份证号码
  var userCard = IDCard;
  //如果身份证号码为undefind则返回空
  if(!userCard){
    return age;
  }
  var reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/; //验证身份证号码的正则
  if (reg.test(userCard)) {
    if (userCard.length === 15) {
      var org_birthday = userCard.substring(6, 12);
      //获取出生年月日
      yearBirth = "19" + org_birthday.substring(0, 2);
      console.log(yearBirth)
      monthBirth = org_birthday.substring(2, 4);
      dayBirth = org_birthday.substring(4, 6);
    } else if (userCard.length === 18) {
      //获取出生年月日
      yearBirth = userCard.substring(6,10);
      monthBirth = userCard.substring(10,12);
      dayBirth = userCard.substring(12,14);

    }
    //获取当前年月日并计算年龄
    var myDate = new Date();
    var monthNow = myDate.getMonth() + 1;
    var dayNow = myDate.getDate();
    var age = myDate.getFullYear() - yearBirth;
    if(monthNow < monthBirth || (monthNow === monthBirth && dayNow < dayBirth)){
      age--;
    }
    //返回年龄
    return age;
  } else {
    return ''
  }
}
