<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <debounce-button :loading="loading.submit" type="primary" @click="dataProcessing">
          保存
        </debounce-button>
        <el-button @click="$router.push({ name: 'account'})">
          <i class="iconfont icon-fanhui" />返回
        </el-button>
      </template>
    </page-title>
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="开户企业信息">
          <el-col :span="24">
            <el-form-item label="开户类型" prop="userType">
              <el-radio-group v-model="appForm.userType" :disabled="$route.params.editMode === 'edit'" @change="changeUserType">
                <el-radio v-for="item in constants.accountUserType" :key="item.value" :label="item.value">
                  {{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <div v-if="appForm.userType === '12'">
            <el-col :span="12">
              <el-form-item prop="openAccountInfo.companyName" label="公司名称全称">
                <ics-item-inner :prop="appForm.openAccountInfo.companyName" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.openAccountInfo.companyName" placeholder="请输入公司名称全称" @input="appForm.openAccountInfo.companyName=appForm.openAccountInfo.companyName.replace(/[\d]/g,'')" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="12">
              <el-form-item prop="openAccountInfo.companyShortName" label="企业简称">
                <ics-item-inner :prop="appForm.openAccountInfo.companyShortName" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.openAccountInfo.companyShortName" placeholder="请输入企业简称" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="openAccountInfo.socialNo" label="统一社会信用代码">
                <ics-item-inner :prop="appForm.openAccountInfo.socialNo" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.openAccountInfo.socialNo" placeholder="请输入统一社会信用代码" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="openAccountInfo.categoryType" label="营业执照企业类型">
                <ics-item-inner :prop="appForm.openAccountInfo.categoryType" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.openAccountInfo.categoryType" placeholder="请输入营业执照企业类型" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="openAccountInfo.email" label="企业邮箱">
                <ics-item-inner :prop="appForm.openAccountInfo.email" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.openAccountInfo.email" placeholder="请输入企业邮箱" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="openAccountInfo.firmScale" label="企业规模">
                <ics-item-inner :prop="appForm.openAccountInfo.firmScale" :format="(val)=>utils.statusFormat(val,'firmScaleType')">
                  <el-select v-model="appForm.openAccountInfo.firmScale" placeholder="请选择企业规模" filterable>
                    <el-option v-for="item in constants.firmScaleType" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="openAccountInfo.allLicenceDate" label="营业执照起始期">
                <ics-item-inner :prop="appForm.openAccountInfo.allLicenceDate" :format="(val)=>utils.dateFormat(val)">
                  <ics-effective-date :date.sync="appForm.openAccountInfo.allLicenceDate" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="openAccountInfo.basicAcctNo" label="企业银行账户">
                <ics-item-inner :prop="appForm.openAccountInfo.basicAcctNo" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.openAccountInfo.basicAcctNo" placeholder="请输入企业银行账户" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="openAccountInfo.approvalNo" label="基础存款账户编号">
                <ics-item-inner :prop="appForm.openAccountInfo.approvalNo" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.openAccountInfo.approvalNo" placeholder="请输入基础存款账户编号" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="办公地址" prop="openAccountInfo.dsEnterpriseAddress">
                <div style="width: 55%; display: table;">
                  <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                    <el-form-item>
                      <el-cascader
                        v-model="appForm.openAccountInfo.dsEnterpriseAddress"
                        :options="areaList"
                        clearable
                        filterable
                        style="width:366px;"
                        @change="(val)=>changeAddress(val, 'openAccountInfo')"
                      />
                    </el-form-item>
                  </div>
                </div>
                <div style="width: 736px;float:left;margin-right:10px; display: table-cell;">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item>
                        <el-input v-model="appForm.openAccountInfo.addrDetail" style="display:inline" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="营业执照照片" prop="openAccountInfo.licenseUrl">
                <div style="width: 55%; display: table;">
                  <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item label="">
                          <el-upload
                            class="avatar-uploader"
                            :action="fileUploadUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="companyLicenseUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.openAccountInfo.licenseUrl" :src="appForm.openAccountInfo.licenseUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip" style="width: 100px;">
                              上传营业执照照片
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </div>
          <div v-if="appForm.userType === '13'">
            <el-col :span="12">
              <el-form-item prop="onlyOpenAccountInfo.retailerName" label="个体工商字号">
                <ics-item-inner :prop="appForm.onlyOpenAccountInfo.retailerName" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.onlyOpenAccountInfo.retailerName" placeholder="请输入个体工商字号" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="12">
              <el-form-item prop="onlyOpenAccountInfo.retailerLicenseIssDate" label="注册日期">
                <ics-item-inner :prop="appForm.onlyOpenAccountInfo.retailerLicenseIssDate" :format="(val)=>utils.dateFormat(val)">
                  <ics-search-date :date.sync="appForm.onlyOpenAccountInfo.retailerLicenseIssDate" type="date" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="onlyOpenAccountInfo.socialNo" label="统一社会信用代码">
                <ics-item-inner :prop="appForm.onlyOpenAccountInfo.socialNo" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.onlyOpenAccountInfo.socialNo" placeholder="请输入统一社会信用代码" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="onlyOpenAccountInfo.allLicenceDate" label="营业执照起始期">
                <ics-item-inner :prop="appForm.onlyOpenAccountInfo.allLicenceDate" :format="(val)=>utils.dateFormat(val)">
                  <ics-effective-date :date.sync="appForm.onlyOpenAccountInfo.allLicenceDate" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="办公地址" prop="onlyOpenAccountInfo.dsEnterpriseAddress">
                <div style="width: 55%; display: table;">
                  <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                    <el-form-item>
                      <el-cascader
                        v-model="appForm.onlyOpenAccountInfo.dsEnterpriseAddress"
                        :options="areaList"
                        clearable
                        filterable
                        style="width:366px;"
                        @change="(val)=>changeAddress(val, 'onlyOpenAccountInfo')"
                      />
                    </el-form-item>
                  </div>
                </div>
                <div style="width: 736px;float:left;margin-right:10px; display: table-cell;">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item>
                        <el-input v-model="appForm.onlyOpenAccountInfo.addrDetail" style="display:inline" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="营业执照照片">
                <div style="width: 55%; display: table;">
                  <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item label="" prop="onlyOpenAccountInfo.licenseUrl">
                          <el-upload
                            class="avatar-uploader"
                            :action="fileUploadUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="onlyLicenseUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.onlyOpenAccountInfo.licenseUrl" :src="appForm.onlyOpenAccountInfo.licenseUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip" style="width: 100px;">
                              上传营业执照照片
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </div>
        </ics-page-inner>
        <div v-if="appForm.userType === '12'">
          <ics-page-inner title="开户人信息">
            <el-col :span="12">
              <el-form-item prop="companyLegalPersonInfo.legalPerson" label="法定代表人姓名">
                <ics-item-inner :prop="appForm.companyLegalPersonInfo.legalPerson" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.companyLegalPersonInfo.legalPerson" placeholder="请输入法定代表人姓名" @input="appForm.companyLegalPersonInfo.legalPerson=appForm.companyLegalPersonInfo.legalPerson.replace(/[\d]/g,'')" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="companyLegalPersonInfo.legalPhone" label="法定代表人联系电话">
                <ics-item-inner :prop="appForm.companyLegalPersonInfo.legalPhone" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.companyLegalPersonInfo.legalPhone" type="number" placeholder="请输入法定代表人联系电话" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="companyLegalPersonInfo.legalCredentialType" label="证件类型">
                <ics-item-inner :prop="appForm.companyLegalPersonInfo.legalCredentialType" :format="(val)=>utils.statusFormat(val, 'legalCredentialType')">
                  <el-select v-model="appForm.companyLegalPersonInfo.legalCredentialType" placeholder="请选择证件类型" style="width: 100%" filterable>
                    <el-option v-for="item in constants.legalCredentialType" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="companyLegalPersonInfo.legalIdentity" label="证件号码">
                <ics-item-inner :prop="appForm.companyLegalPersonInfo.legalIdentity" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.companyLegalPersonInfo.legalIdentity" placeholder="请输入证件号码" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="companyLegalPersonInfo.legalPersonDate" label="证件有效期">
                <ics-item-inner :prop="appForm.companyLegalPersonInfo.legalPersonDate" :format="(val)=>utils.dateFormat(val)">
                  <ics-effective-date :date.sync="appForm.companyLegalPersonInfo.legalPersonDate" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="24">
              <el-form-item label="法定代表人身份证照片">
                <div style="width: 55%; display: table;">
                  <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item prop="companyLegalPersonInfo.legalSnBackImgsUrl">
                          <el-upload
                            class="avatar-uploader"
                            :action="fileUploadUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="legalSnBackImgsUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.companyLegalPersonInfo.legalSnBackImgsUrl" :src="appForm.companyLegalPersonInfo.legalSnBackImgsUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip">
                              上传身份证正面
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <div style="width: 25%;float:left;margin-right:10px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item prop="companyLegalPersonInfo.legalSnPreImgsUrl">
                          <el-upload
                            class="avatar-uploader"
                            :action="fileUploadUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="legalSnPreImgsUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.companyLegalPersonInfo.legalSnPreImgsUrl" :src="appForm.companyLegalPersonInfo.legalSnPreImgsUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip" style="width: 100px;">
                              上传身份证反面
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="consigneeInfo.consigneeName" label="经办人姓名">
                <ics-item-inner :prop="appForm.consigneeInfo.consigneeName" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.consigneeInfo.consigneeName" placeholder="请输入经办人姓名" @input="appForm.consigneeInfo.consigneeName=appForm.consigneeInfo.consigneeName.replace(/[\d]/g,'')" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="consigneeInfo.consigneeContactNumber" label="经办人联系电话">
                <ics-item-inner :prop="appForm.consigneeInfo.consigneeContactNumber" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.consigneeInfo.consigneeContactNumber" type="number" placeholder="请输入经办人联系电话" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件类型">
                <p>{{ utils.statusFormat(appForm.consigneeInfo.consigneeCredentialType, 'legalCredentialType') }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="consigneeInfo.consigneeCredentialNumber" label="证件号码">
                <ics-item-inner :prop="appForm.consigneeInfo.consigneeCredentialNumber" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.consigneeInfo.consigneeCredentialNumber" placeholder="请输入证件号码" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="consigneeInfo.consigneeDate" label="证件有效期">
                <ics-item-inner :prop="appForm.consigneeInfo.consigneeDate" :format="(val)=>utils.dateFormat(val)">
                  <ics-effective-date :date.sync="appForm.consigneeInfo.consigneeDate" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="24">
              <el-form-item label="经办人身份证照片">
                <div style="width: 55%; display: table;">
                  <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item prop="consigneeInfo.consigneeSnBackImgsUrl">
                          <el-upload
                            class="avatar-uploader"
                            :action="fileUploadUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="consigneeSnBackImgsUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.consigneeInfo.consigneeSnBackImgsUrl" :src="appForm.consigneeInfo.consigneeSnBackImgsUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip">
                              上传身份证正面
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <div style="width: 25%;float:left;margin-right:10px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item prop="consigneeInfo.consigneeSnPreImgsUrl">
                          <el-upload
                            class="avatar-uploader"
                            :action="fileUploadUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="consigneeSnPreImgsUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.consigneeInfo.consigneeSnPreImgsUrl" :src="appForm.consigneeInfo.consigneeSnPreImgsUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip" style="width: 100px;">
                              上传身份证反面
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </ics-page-inner>
        </div>
        <div v-if="appForm.userType === '11'">
          <ics-page-inner title="开户人信息">
            <el-col :span="12">
              <el-form-item prop="personalOpenAccountInfo.userName" label="用户姓名">
                <ics-item-inner :prop="appForm.personalOpenAccountInfo.userName" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.personalOpenAccountInfo.userName" placeholder="请输入用户姓名" @input="appForm.personalOpenAccountInfo.userName=appForm.personalOpenAccountInfo.userName.replace(/[\d]/g,'')" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="12">
              <el-form-item prop="personalOpenAccountInfo.email" label="个人邮箱">
                <ics-item-inner :prop="appForm.personalOpenAccountInfo.email" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.personalOpenAccountInfo.email" placeholder="请输入个人邮箱" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="personalOpenAccountInfo.phoneNumber" label="开户人联系电话">
                <ics-item-inner :prop="appForm.personalOpenAccountInfo.phoneNumber" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.personalOpenAccountInfo.phoneNumber" type="number" placeholder="请输入开户人联系电话" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="联系地址" prop="personalOpenAccountInfo.dsEnterpriseAddress">
                <div style="width: 55%; display: table;">
                  <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                    <el-form-item>
                      <el-cascader
                        v-model="appForm.personalOpenAccountInfo.dsEnterpriseAddress"
                        :options="areaList"
                        clearable
                        filterable
                        style="width:366px;"
                        @change="(val)=>changeAddress(val, 'personalOpenAccountInfo')"
                      />
                    </el-form-item>
                  </div>
                </div>
                <div style="width: 736px;float:left;margin-right:10px; display: table-cell;">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item>
                        <el-input v-model="appForm.personalOpenAccountInfo.addrDetail" style="display:inline" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件类型">
                <p>{{ utils.statusFormat(appForm.personalOpenAccountInfo.credentialType, 'legalCredentialType') }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="personalOpenAccountInfo.credentialNumber" label="证件号码">
                <ics-item-inner :prop="appForm.personalOpenAccountInfo.credentialNumber" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.personalOpenAccountInfo.credentialNumber" placeholder="请输入证件号码" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="personalOpenAccountInfo.personalDate" label="证件有效期">
                <ics-item-inner :prop="appForm.personalOpenAccountInfo.personalDate" :format="(val)=>utils.dateFormat(val)">
                  <ics-effective-date :date.sync="appForm.personalOpenAccountInfo.personalDate" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="法定代表人身份证照片">
                <div style="width: 55%; display: table;">
                  <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item prop="personalOpenAccountInfo.snPreImgsUrl">
                          <el-upload
                            class="avatar-uploader"
                            :action="fileUploadUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="snPreImgsUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.personalOpenAccountInfo.snPreImgsUrl" :src="appForm.personalOpenAccountInfo.snPreImgsUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip">
                              上传身份证正面
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <div style="width: 25%;float:left;margin-right:10px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item prop="personalOpenAccountInfo.snBackImgsUrl">
                          <el-upload
                            class="avatar-uploader"
                            :headers="headers"
                            :action="fileUploadUrl"
                            :show-file-list="false"
                            :on-success="snBackImgsUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.personalOpenAccountInfo.snBackImgsUrl" :src="appForm.personalOpenAccountInfo.snBackImgsUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip" style="width: 100px;">
                              上传身份证反面
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </ics-page-inner>
        </div>
        <div v-if="appForm.userType === '13'">
          <ics-page-inner title="开户经营者信息">
            <el-col :span="12">
              <el-form-item prop="managementInfo.legalPerson" label="经营者姓名">
                <ics-item-inner :prop="appForm.managementInfo.legalPerson" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.managementInfo.legalPerson" placeholder="请输入经营者姓名" @input="appForm.managementInfo.legalPerson=appForm.managementInfo.legalPerson.replace(/[\d]/g,'')" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="12">
              <el-form-item prop="managementInfo.legalPhone" label="经营者联系电话">
                <ics-item-inner :prop="appForm.managementInfo.legalPhone" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.managementInfo.legalPhone" type="number" placeholder="请输入经营者联系电话" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="managementInfo.legalEmail" label="经营者邮箱">
                <ics-item-inner :prop="appForm.managementInfo.legalEmail" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.managementInfo.legalEmail" placeholder="请输入经营者邮箱" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件类型">
                <p>{{ utils.statusFormat(appForm.managementInfo.legalCredentialType, 'legalCredentialType') }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="managementInfo.legalIdentity" label="证件号码">
                <ics-item-inner :prop="appForm.managementInfo.legalIdentity" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.managementInfo.legalIdentity" placeholder="请输入证件号码" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="managementInfo.legalPersonDate" label="证件有效期">
                <ics-item-inner :prop="appForm.managementInfo.legalPersonDate" :format="(val)=>utils.dateFormat(val)">
                  <ics-effective-date :date.sync="appForm.managementInfo.legalPersonDate" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col :span="24">
              <el-form-item label="经营者身份证照片" class="required-th">
                <div style="width: 55%; display: table;">
                  <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item prop="managementInfo.legalSnBackImgsUrl">
                          <el-upload
                            class="avatar-uploader"
                            :action="fileUploadUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="manageLegalSnBackImgsUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.managementInfo.legalSnBackImgsUrl" :src="appForm.managementInfo.legalSnBackImgsUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip">
                              上传身份证正面
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <div style="width: 25%;float:left;margin-right:10px; display: table-cell;">
                    <el-row>
                      <el-col :span="12" style="width: 120px;">
                        <el-form-item prop="managementInfo.legalSnPreImgsUrl">
                          <el-upload
                            class="avatar-uploader"
                            :action="fileUploadUrl"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="manageLegalSnPreImgsUrlSuccess"
                            :on-error="onError"
                            :before-upload="beforeUpload"
                          >
                            <img v-if="appForm.managementInfo.legalSnPreImgsUrl" :src="appForm.managementInfo.legalSnPreImgsUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                            <div slot="tip" class="el-upload__tip" style="width: 100px;">
                              上传身份证反面
                            </div>
                          </el-upload>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </ics-page-inner>
        </div>
        <ics-page-inner :show-header="true">
          <ics-button-inner :loading="loading.submit" submit-title="提交" cancel-title="返回" :show-cancel="false" @submit="dataProcessing(1)" />
        </ics-page-inner>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsEffectiveDate from './components/effective-date'
import IcsSearchDate from '@/components/search-date'
import axios from 'axios'
import {isIdentityId} from "../../../components/template/validate12";
import utils from "../../../assets/js/utils";
export default {
  components: { IcsSearchDate, IcsEffectiveDate },
  mixins: [routeEnterMixin],
  data() {
    const checkEmail = (rule, value, callback) => {
      if (value) {
        if (/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
          callback()
        } else {
          callback(new Error('邮箱格式不正确'))
        }
      }
      callback()
    }
    const checkIdentitytionId  = (rule, value, callback) => {
      let errorMsg = isIdentityId(value);
      if (errorMsg !== '') {
        callback(new Error(errorMsg));
      } else {
        callback()
      }
    }
    const checkIdentitytionId12  = (rule, value, callback) => {
      if (this.appForm.companyLegalPersonInfo.legalCredentialType === '0') {
        let errorMsg = isIdentityId(value);
        if (errorMsg !== '') {
          callback(new Error(errorMsg));
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validatePhone  = (rule, value, callback) => {
      if (/^\d{3}-\d{8}|\d{4}-\d{7,8}$/.test(value) || /^1[3|4|5|7|8|9][0-9]\d{8}$/.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的联系电话'))
      }
    }
    const validatorDate  = (rule, value, callback) => {
      const end = value[1]
      const start = value[0]
      if (utils.dateFormat(start) === utils.dateFormat(end)) {
        callback(new Error('开始时间与结束时间不能一致'))
      } else {
        callback()
      }
    }
    return {
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/open/upload?bucket=other',
      userId: this.$route.query.userId,
      isShowAddressInfo: false,
      headers: {
        Token: this.$store.state.token
      },
      appForm: {
        userType: '12',
        openAccountInfo: {
          companyName: '',
          socialNo: '',
          companyShortName: '',
          categoryType: '',
          email: '',
          firmScale: '',
          allLicenceDate: [],
          basicAcctNo: '',
          approvalNo: '',
          dsEnterpriseAddress: [],
          province: '',
          city: '',
          district: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
          addrDetail: '',
          licenseUrl: ''
        },
        companyLegalPersonInfo: {
          legalPerson: '',
          legalPhone: '',
          legalCredentialType: '',
          legalIdentity: '',
          legalPersonDate: [],
          legalSnBackImgsUrl: '',
          legalSnPreImgsUrl: ''
        },
        consigneeInfo: {
          consigneeName: '',
          consigneeContactNumber: '',
          consigneeCredentialType: '0',
          consigneeCredentialNumber: '',
          consigneeDate: [],
          consigneeSnBackImgsUrl: '',
          consigneeSnPreImgsUrl: ''
        },
        personalOpenAccountInfo: {
          userName: '',
          email: '',
          phoneNumber: '',
          credentialType: '0',
          credentialNumber: '',
          personalDate: [],
          snPreImgsUrl: '',
          snBackImgsUrl: '',
          dsEnterpriseAddress: [],
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          district: '',
          districtCode: '',
          addrDetail: ''
        },
        onlyOpenAccountInfo: {
          retailerName: '',
          retailerLicenseIssDate: '',
          socialNo: '',
          allLicenceDate: [],
          dsEnterpriseAddress: [],
          province: '',
          city: '',
          district: '',
          provinceCode: '',
          cityCode: '',
          districtCode: '',
          addrDetail: '',
          licenseUrl: ''
        },
        managementInfo:{
          legalPerson: '',
          legalPhone: '',
          legalEmail: '',
          legalCredentialType: '0',
          legalIdentity: '',
          legalPersonDate: [],
          legalSnBackImgsUrl: '',
          legalSnPreImgsUrl: ''
        }
      },
      accountInfo: {
        eaccountStatus: '' || ''
      },
      areaList: [],
      validateFlag: 0,
      rules: {
        userType: [
          { required: true, message: '请选择开户类型', trigger: 'change' }
        ],
        openAccountInfo: {
          companyName: [
            { required: false, message: '请输入公司名称全称', trigger: 'blur' }
          ],
          socialNo: [
            { required: false, message: '请输入统一社会信用代码', trigger: 'blur' }
          ],
          companyShortName: [
            { required: false, message: '请输入企业简称', trigger: 'blur' }
          ],
          categoryType: [
            { required: false, message: '请输入营业执照企业类型', trigger: 'blur' }
          ],
          email: [
            { required: false, message: '请输入企业邮箱', trigger: 'blur' },
            { validator: checkEmail, trigger: 'blur' }
          ],
          firmScale: [
            { required: false, message: '请选择企业规模', trigger: 'change' }
          ],
          allLicenceDate: [
            { required: false, message: '请选择营业执照起始期', trigger: 'change' }
          ],
          basicAcctNo: [
            { required: false, message: '请输入企业银行账户', trigger: 'blur' }
          ],
          approvalNo: [
            { required: false, message: '请输入基础存款账户编号', trigger: 'blur' }
          ],
          dsEnterpriseAddress: [
            { required: false, message: '请选择办公地址', trigger: 'change' }
          ],
          licenseUrl: [
            { required: false, message: '请上传营业执照', trigger: 'change' }
          ],
        },
        companyLegalPersonInfo: {
          legalPerson: [
            { required: false, message: '请输入法定代表人姓名', trigger: 'blur' }
          ],
          legalPhone: [
            { required: false, message: '请输入法定代表人联系电话', trigger: 'blur' },
            { validator: validatePhone, trigger: 'blur' }
          ],
          legalCredentialType: [
            { required: false, message: '请选择证件类型', trigger: 'change' }
          ],
          legalIdentity: [
            { required: false, message: '请输入证件号码', trigger: 'blur' },
            { validator: checkIdentitytionId12 , trigger: 'blur' }
          ],
          legalPersonDate: [
            { required: false, message: '请选择证件有效期', trigger: 'change' },
            { validator: validatorDate , trigger: 'change' }
          ],
          legalSnBackImgsUrl: [
            { required: false, message: '请上传证件照片人像面', trigger: 'change' }
          ],
          legalSnPreImgsUrl: [
            { required: false, message: '请上传证件照片国徽面', trigger: 'change' }
          ],
        },
        consigneeInfo: {
          consigneeName: [
            { required: false, message: '请输入经办人姓名', trigger: 'blur' }
          ],
          consigneeContactNumber: [
            { required: false, message: '请输入经办人联系电话', trigger: 'blur' },
            { validator: validatePhone, trigger: 'blur' }
          ],
          consigneeCredentialNumber: [
            { required: false, message: '请输入证件号码', trigger: 'blur' },
            { validator: checkIdentitytionId , trigger: "blur" }
          ],
          consigneeDate: [
            { required: false, message: '请选择证件有效期', trigger: 'change' },
            { validator: validatorDate , trigger: 'change' }
          ],
          consigneeSnBackImgsUrl: [
            { required: false, message: '请上传证件照片人像面', trigger: 'change' }
          ],
          consigneeSnPreImgsUrl: [
            { required: false, message: '请上传证件照片国徽面', trigger: 'change' }
          ],
        },
        personalOpenAccountInfo: {
          userName: [
            { required: false, message: '请输入用户姓名', trigger: 'blur' }
          ],
          email: [
            { required: false, message: '请输入个人邮箱', trigger: 'blur' },
            { validator: checkEmail, trigger: 'blur' }
          ],
          phoneNumber: [
            { required: false, message: '请输入开户人联系电话', trigger: 'blur' },
            { validator: validatePhone, trigger: 'blur' }
          ],
          credentialNumber: [
            { required: false, message: '请输入证件号码', trigger: 'blur' },
            { validator: checkIdentitytionId , trigger: "blur" }
          ],
          personalDate: [
            { required: false, message: '请选择证件有效期', trigger: 'blur' },
            { validator: validatorDate , trigger: 'change' }
          ],
          dsEnterpriseAddress: [
            { required: false, message: '请选择联系地址', trigger: 'change' }
          ],
          snPreImgsUrl: [
            { required: false, message: '请上传证件照片人像面', trigger: 'change' }
          ],
          snBackImgsUrl: [
            { required: false, message: '请上传证件照片国徽面', trigger: 'change' }
          ],
        },
        onlyOpenAccountInfo: {
          retailerName: [
            { required: false, message: '请输入个体工商字号', trigger: 'blur' }
          ],
          retailerLicenseIssDate: [
            { required: false, message: '请选择注册日期', trigger: 'change' }
          ],
          socialNo: [
            { required: false, message: '请输入统一社会信用代码', trigger: 'blur' }
          ],
          allLicenceDate: [
            { required: false, message: '请选择营业执照起始期', trigger: 'change' }
          ],
          dsEnterpriseAddress: [
            { required: false, message: '请选择办公地址', trigger: 'change' }
          ],
          licenseUrl: [
            { required: false, message: '请上传营业执照照片', trigger: 'change' }
          ],
        },
        managementInfo:{
          legalPerson: [
            { required: false, message: '请输入经营者姓名', trigger: 'blur' }
          ],
          legalPhone: [
            { required: false, message: '请输入经营者联系电话', trigger: 'blur' },
            { validator: validatePhone, trigger: 'blur' }
          ],
          legalEmail: [
            { required: false, message: '请输入经营者邮箱', trigger: 'blur' },
            { validator: checkEmail, trigger: 'blur' }
          ],
          legalIdentity: [
            { required: false, message: '请输入证件号码', trigger: 'blur' },
            { validator: checkIdentitytionId , trigger: "blur" }
          ],
          legalPersonDate: [
            { required: false, message: '请选择证件有效期', trigger: 'change' },
            { validator: validatorDate , trigger: 'change' }
          ],
          legalSnBackImgsUrl: [
            { required: false, message: '请上传证件照片人像面', trigger: 'change' }
          ],
          legalSnPreImgsUrl: [
            { required: false, message: '请上传证件照片国徽面', trigger: 'change' }
          ],
        }
      }
    }
  },
  created() {
    this.changeUserType()
    // 获得省市区地址
    this.getAddress()
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.system.account.getAccountDetail(this.userId).then(result => {
        const data = result.data.data
        this.appForm.firmId = data.firmId
        this.appForm.userType = data.userType
        if (data.eaccountMessage) {
          this.$message.warning(data.eaccountMessage)
        }
        this.accountInfo = data || {}
        let openAccountInfo = JSON.parse(data.openAccountInfo)
        let legalPersonInfo = JSON.parse(data.legalPersonInfo)
        let consigneeInfo = JSON.parse(data.consigneeInfo)
        switch (data.userType) {
          case '12':
            this.appForm.openAccountInfo.companyName = openAccountInfo.companyName
            this.appForm.openAccountInfo.socialNo = openAccountInfo.socialNo
            this.appForm.openAccountInfo.companyShortName = openAccountInfo.companyShortName
            this.appForm.openAccountInfo.categoryType = openAccountInfo.categoryType
            this.appForm.openAccountInfo.email = openAccountInfo.email
            this.appForm.openAccountInfo.firmScale = openAccountInfo.firmScale
            if (openAccountInfo.allLicenceIssDate && openAccountInfo.allLicenceExpiryDate) {
              this.appForm.openAccountInfo.allLicenceDate = [openAccountInfo.allLicenceIssDate, openAccountInfo.allLicenceExpiryDate]
            }
            this.appForm.openAccountInfo.basicAcctNo = openAccountInfo.basicAcctNo
            this.appForm.openAccountInfo.approvalNo = openAccountInfo.approvalNo
            if (openAccountInfo.provinceCode && openAccountInfo.cityCode && openAccountInfo.districtCode) {
              this.appForm.openAccountInfo.dsEnterpriseAddress = [openAccountInfo.provinceCode, openAccountInfo.cityCode, openAccountInfo.districtCode]
            }
            this.appForm.openAccountInfo.province = openAccountInfo.province
            this.appForm.openAccountInfo.city = openAccountInfo.city
            this.appForm.openAccountInfo.district = openAccountInfo.district
            this.appForm.openAccountInfo.provinceCode = openAccountInfo.provinceCode
            this.appForm.openAccountInfo.cityCode = openAccountInfo.cityCode
            this.appForm.openAccountInfo.districtCode = openAccountInfo.districtCode
            this.appForm.openAccountInfo.addrDetail = openAccountInfo.addrDetail
            this.appForm.openAccountInfo.licenseUrl = openAccountInfo.licenseUrl
            this.appForm.companyLegalPersonInfo.legalPerson = legalPersonInfo.legalPerson
            this.appForm.companyLegalPersonInfo.legalPhone = legalPersonInfo.legalPhone
            this.appForm.companyLegalPersonInfo.legalCredentialType = legalPersonInfo.legalCredentialType
            this.appForm.companyLegalPersonInfo.legalIdentity = legalPersonInfo.legalIdentity
            if (legalPersonInfo.legalPersonIssDate && legalPersonInfo.legalPersonExpiryDate) {
              this.appForm.companyLegalPersonInfo.legalPersonDate = [legalPersonInfo.legalPersonIssDate, legalPersonInfo.legalPersonExpiryDate]
            }
            this.appForm.companyLegalPersonInfo.legalSnBackImgsUrl = legalPersonInfo.legalSnBackImgsUrl
            this.appForm.companyLegalPersonInfo.legalSnPreImgsUrl = legalPersonInfo.legalSnPreImgsUrl
            this.appForm.consigneeInfo.consigneeName = consigneeInfo.consigneeName
            this.appForm.consigneeInfo.consigneeContactNumber = consigneeInfo.consigneeContactNumber
            this.appForm.consigneeInfo.consigneeCredentialType = consigneeInfo.consigneeCredentialType
            this.appForm.consigneeInfo.consigneeCredentialNumber = consigneeInfo.consigneeCredentialNumber
            if (consigneeInfo.consigneeIssDate && consigneeInfo.consigneeExpiryDate) {
              this.appForm.consigneeInfo.consigneeDate = [consigneeInfo.consigneeIssDate, consigneeInfo.consigneeExpiryDate]
            }
            this.appForm.consigneeInfo.consigneeSnBackImgsUrl = consigneeInfo.consigneeSnBackImgsUrl
            this.appForm.consigneeInfo.consigneeSnPreImgsUrl = consigneeInfo.consigneeSnPreImgsUrl
            break
          case '11':
            this.appForm.personalOpenAccountInfo.userName = openAccountInfo.userName
            this.appForm.personalOpenAccountInfo.email = openAccountInfo.email
            this.appForm.personalOpenAccountInfo.phoneNumber = openAccountInfo.phoneNumber,
              this.appForm.personalOpenAccountInfo.credentialType = openAccountInfo.credentialType
            this.appForm.personalOpenAccountInfo.credentialNumber = openAccountInfo.credentialNumber
            if (openAccountInfo.issDate && openAccountInfo.expiryDate) {
              this.appForm.personalOpenAccountInfo.personalDate = [openAccountInfo.issDate, openAccountInfo.expiryDate]
            }
            this.appForm.personalOpenAccountInfo.snPreImgsUrl = openAccountInfo.snPreImgsUrl
            this.appForm.personalOpenAccountInfo.snBackImgsUrl = openAccountInfo.snBackImgsUrl
            if (openAccountInfo.provinceCode && openAccountInfo.cityCode && openAccountInfo.districtCode) {
              this.appForm.personalOpenAccountInfo.dsEnterpriseAddress = [openAccountInfo.provinceCode, openAccountInfo.cityCode, openAccountInfo.districtCode]
            }
            this.appForm.personalOpenAccountInfo.province = openAccountInfo.province
            this.appForm.personalOpenAccountInfo.provinceCode = openAccountInfo.provinceCode
            this.appForm.personalOpenAccountInfo.city = openAccountInfo.city
            this.appForm.personalOpenAccountInfo.cityCode = openAccountInfo.cityCode
            this.appForm.personalOpenAccountInfo.district = openAccountInfo.district
            this.appForm.personalOpenAccountInfo.districtCode = openAccountInfo.districtCode
            this.appForm.personalOpenAccountInfo.addrDetail = openAccountInfo.addrDetail
            break
          case '13':
            this.appForm.onlyOpenAccountInfo.retailerName = openAccountInfo.retailerName
            this.appForm.onlyOpenAccountInfo.retailerLicenseIssDate = openAccountInfo.retailerLicenseIssDate
            this.appForm.onlyOpenAccountInfo.socialNo = openAccountInfo.socialNo
            if (openAccountInfo.allLicenceIssDate && openAccountInfo.allLicenceExpiryDate) {
              this.appForm.onlyOpenAccountInfo.allLicenceDate = [openAccountInfo.allLicenceIssDate, openAccountInfo.allLicenceExpiryDate]
            }
            if (openAccountInfo.provinceCode && openAccountInfo.cityCode && openAccountInfo.districtCode) {
              this.appForm.onlyOpenAccountInfo.dsEnterpriseAddress = [openAccountInfo.provinceCode, openAccountInfo.cityCode, openAccountInfo.districtCode]
            }
            this.appForm.onlyOpenAccountInfo.province = openAccountInfo.province
            this.appForm.onlyOpenAccountInfo.city = openAccountInfo.city
            this.appForm.onlyOpenAccountInfo.district = openAccountInfo.district
            this.appForm.onlyOpenAccountInfo.provinceCode = openAccountInfo.provinceCode
            this.appForm.onlyOpenAccountInfo.cityCode = openAccountInfo.cityCode
            this.appForm.onlyOpenAccountInfo.districtCode = openAccountInfo.districtCode
            this.appForm.onlyOpenAccountInfo.addrDetail = openAccountInfo.addrDetail
            this.appForm.onlyOpenAccountInfo.licenseUrl = openAccountInfo.licenseUrl
            this.appForm.managementInfo.legalPerson = legalPersonInfo.legalPerson
            this.appForm.managementInfo.legalPhone = legalPersonInfo.legalPhone
            this.appForm.managementInfo.legalEmail = legalPersonInfo.legalEmail
            this.appForm.managementInfo.legalCredentialType = legalPersonInfo.legalCredentialType
            this.appForm.managementInfo.legalIdentity = legalPersonInfo.legalIdentity
            if (legalPersonInfo.legalPersonIssDate && legalPersonInfo.legalPersonExpiryDate) {
              this.appForm.managementInfo.legalPersonDate = [legalPersonInfo.legalPersonIssDate, legalPersonInfo.legalPersonExpiryDate]
            }
            this.appForm.managementInfo.legalSnBackImgsUrl = legalPersonInfo.legalSnBackImgsUrl,
              this.appForm.managementInfo.legalSnPreImgsUrl = legalPersonInfo.legalSnPreImgsUrl
            break
          default:
            return false
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    companyLicenseUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.openAccountInfo.licenseUrl = data.url
      console.log(this.appForm.openAccountInfo.licenseUrl)
    },
    onlyLicenseUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.onlyOpenAccountInfo.licenseUrl = data.url
      console.log(this.appForm.onlyOpenAccountInfo.licenseUrl)
    },
    legalSnBackImgsUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.companyLegalPersonInfo.legalSnBackImgsUrl = data.url
    },
    legalSnPreImgsUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.companyLegalPersonInfo.legalSnPreImgsUrl = data.url
    },
    snPreImgsUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.personalOpenAccountInfo.snPreImgsUrl = data.url
    },
    snBackImgsUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.personalOpenAccountInfo.snBackImgsUrl = data.url
    },
    manageLegalSnBackImgsUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.managementInfo.legalSnBackImgsUrl = data.url
    },
    manageLegalSnPreImgsUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.managementInfo.legalSnPreImgsUrl = data.url
    },
    consigneeSnBackImgsUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.consigneeInfo.consigneeSnBackImgsUrl = data.url
    },
    consigneeSnPreImgsUrlSuccess (response, file) {
      const data = response.data
      const bucketParams = this.utils.encryptRSA('other').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.url = `${data.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.consigneeInfo.consigneeSnPreImgsUrl = data.url
    },
    beforeUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpg', 'png', 'jpeg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: '上传文件只能是 jpg、png、jpeg格式',
          type: 'warning'
        })
        return false
      }
      const isLt = file.size / 1024 / 1024 < 2
      if (!isLt) {
        this.$message.error('上传文件最大为2M')
        return isLt
      }
    },
    onError (err, file) {
      this.$message.error(JSON.parse(err.message).message || '上传失败')
    },
    changeUserType (val) {
      const data = this.appForm.userType ? this.appForm.userType : val
      if (this.validateFlag === 1) {
        this.$refs.appForm.clearValidate()
      }
      if (this.userId) {
        this.getDetail()
      }
      this.changeUserTypeInitial()
      switch (data) {
        case '12':
          this.rules.openAccountInfo.companyName[0].required = true
          this.rules.openAccountInfo.socialNo[0].required = true
          this.rules.openAccountInfo.companyShortName[0].required = true
          this.rules.openAccountInfo.categoryType[0].required = true
          this.rules.openAccountInfo.email[0].required = true
          this.rules.openAccountInfo.firmScale[0].required = true
          this.rules.openAccountInfo.allLicenceDate[0].required = true
          this.rules.openAccountInfo.basicAcctNo[0].required = true
          this.rules.openAccountInfo.approvalNo[0].required = true
          this.rules.openAccountInfo.dsEnterpriseAddress[0].required = true
          this.rules.openAccountInfo.licenseUrl[0].required = true
          this.rules.companyLegalPersonInfo.legalPerson[0].required = true
          this.rules.companyLegalPersonInfo.legalPhone[0].required = true
          this.rules.companyLegalPersonInfo.legalCredentialType[0].required = true
          this.rules.companyLegalPersonInfo.legalIdentity[0].required = true
          this.rules.companyLegalPersonInfo.legalPersonDate[0].required = true
          this.rules.companyLegalPersonInfo.legalSnBackImgsUrl[0].required = true
          this.rules.companyLegalPersonInfo.legalSnPreImgsUrl[0].required = true
          this.rules.consigneeInfo.consigneeName[0].required = true
          this.rules.consigneeInfo.consigneeContactNumber[0].required = true
          this.rules.consigneeInfo.consigneeCredentialNumber[0].required = true
          this.rules.consigneeInfo.consigneeDate[0].required = true
          this.rules.consigneeInfo.consigneeSnBackImgsUrl[0].required = true
          this.rules.consigneeInfo.consigneeSnPreImgsUrl[0].required = true
          break
        case '11':
          this.rules.personalOpenAccountInfo.userName[0].required = true
          this.rules.personalOpenAccountInfo.email[0].required = true
          this.rules.personalOpenAccountInfo.phoneNumber[0].required = true
          this.rules.personalOpenAccountInfo.credentialNumber[0].required = true
          this.rules.personalOpenAccountInfo.personalDate[0].required = true
          this.rules.personalOpenAccountInfo.snPreImgsUrl[0].required = true
          this.rules.personalOpenAccountInfo.snBackImgsUrl[0].required = true
          this.rules.personalOpenAccountInfo.dsEnterpriseAddress[0].required = true
          break
        case '13':
          this.rules.onlyOpenAccountInfo.retailerName[0].required = true
          this.rules.onlyOpenAccountInfo.retailerLicenseIssDate[0].required = true
          this.rules.onlyOpenAccountInfo.socialNo[0].required = true
          this.rules.onlyOpenAccountInfo.allLicenceDate[0].required = true
          this.rules.onlyOpenAccountInfo.dsEnterpriseAddress[0].required = true
          this.rules.onlyOpenAccountInfo.licenseUrl[0].required = true
          this.rules.managementInfo.legalPerson[0].required = true
          this.rules.managementInfo.legalPhone[0].required = true
          this.rules.managementInfo.legalEmail[0].required = true
          this.rules.managementInfo.legalIdentity[0].required = true
          this.rules.managementInfo.legalPersonDate[0].required = true
          this.rules.managementInfo.legalSnBackImgsUrl[0].required = true
          this.rules.managementInfo.legalSnPreImgsUrl[0].required = true
          break
        default:
          this.rules.openAccountInfo.companyName[0].required = false
          this.rules.openAccountInfo.socialNo[0].required = false
          this.rules.openAccountInfo.companyShortName[0].required = false
          this.rules.openAccountInfo.categoryType[0].required = false
          this.rules.openAccountInfo.email[0].required = false
          this.rules.openAccountInfo.firmScale[0].required = false
          this.rules.openAccountInfo.allLicenceDate[0].required = false
          this.rules.openAccountInfo.basicAcctNo[0].required = false
          this.rules.openAccountInfo.approvalNo[0].required = false
          this.rules.openAccountInfo.dsEnterpriseAddress[0].required = false
          this.rules.openAccountInfo.licenseUrl[0].required = false
          this.rules.companyLegalPersonInfo.legalPerson[0].required = false
          this.rules.companyLegalPersonInfo.legalPhone[0].required = false
          this.rules.companyLegalPersonInfo.legalCredentialType[0].required = false
          this.rules.companyLegalPersonInfo.legalIdentity[0].required = false
          this.rules.companyLegalPersonInfo.legalPersonDate[0].required = false
          this.rules.companyLegalPersonInfo.legalSnBackImgsUrl[0].required = false
          this.rules.companyLegalPersonInfo.legalSnPreImgsUrl[0].required = false
          this.rules.consigneeInfo.consigneeName[0].required = false
          this.rules.consigneeInfo.consigneeContactNumber[0].required = false
          this.rules.consigneeInfo.consigneeCredentialNumber[0].required = false
          this.rules.consigneeInfo.consigneeDate[0].required = false
          this.rules.consigneeInfo.consigneeSnBackImgsUrl[0].required = false
          this.rules.consigneeInfo.consigneeSnPreImgsUrl[0].required = false
          this.rules.onlyOpenAccountInfo.retailerName[0].required = false
          this.rules.onlyOpenAccountInfo.retailerLicenseIssDate[0].required = false
          this.rules.onlyOpenAccountInfo.socialNo[0].required = false
          this.rules.onlyOpenAccountInfo.allLicenceDate[0].required = false
          this.rules.onlyOpenAccountInfo.dsEnterpriseAddress[0].required = false
          this.rules.onlyOpenAccountInfo.licenseUrl[0].required = false
          this.rules.managementInfo.legalPerson[0].required = false
          this.rules.managementInfo.legalPhone[0].required = false
          this.rules.managementInfo.legalEmail[0].required = false
          this.rules.managementInfo.legalIdentity[0].required = false
          this.rules.managementInfo.legalPersonDate[0].required = false
          this.rules.managementInfo.legalSnBackImgsUrl[0].required = false
          this.rules.managementInfo.legalSnPreImgsUrl[0].required = false
          this.rules.personalOpenAccountInfo.userName[0].required = false
          this.rules.personalOpenAccountInfo.email[0].required = false
          this.rules.personalOpenAccountInfo.phoneNumber[0].required = false
          this.rules.personalOpenAccountInfo.credentialNumber[0].required = false
          this.rules.personalOpenAccountInfo.personalDate[0].required = false
          this.rules.personalOpenAccountInfo.snPreImgsUrl[0].required = false
          this.rules.personalOpenAccountInfo.snBackImgsUrl[0].required = false
          this.rules.personalOpenAccountInfo.dsEnterpriseAddress[0].required = false
          break
      }
    },
    changeUserTypeInitial () {
      this.rules.openAccountInfo.companyName[0].required = false
      this.rules.openAccountInfo.socialNo[0].required = false
      this.rules.openAccountInfo.companyShortName[0].required = false
      this.rules.openAccountInfo.categoryType[0].required = false
      this.rules.openAccountInfo.email[0].required = false
      this.rules.openAccountInfo.firmScale[0].required = false
      this.rules.openAccountInfo.allLicenceDate[0].required = false
      this.rules.openAccountInfo.basicAcctNo[0].required = false
      this.rules.openAccountInfo.approvalNo[0].required = false
      this.rules.openAccountInfo.dsEnterpriseAddress[0].required = false
      this.rules.openAccountInfo.licenseUrl[0].required = false
      this.rules.companyLegalPersonInfo.legalPerson[0].required = false
      this.rules.companyLegalPersonInfo.legalPhone[0].required = false
      this.rules.companyLegalPersonInfo.legalCredentialType[0].required = false
      this.rules.companyLegalPersonInfo.legalIdentity[0].required = false
      this.rules.companyLegalPersonInfo.legalPersonDate[0].required = false
      this.rules.companyLegalPersonInfo.legalSnBackImgsUrl[0].required = false
      this.rules.companyLegalPersonInfo.legalSnPreImgsUrl[0].required = false
      this.rules.consigneeInfo.consigneeName[0].required = false
      this.rules.consigneeInfo.consigneeContactNumber[0].required = false
      this.rules.consigneeInfo.consigneeCredentialNumber[0].required = false
      this.rules.consigneeInfo.consigneeDate[0].required = false
      this.rules.consigneeInfo.consigneeSnBackImgsUrl[0].required = false
      this.rules.consigneeInfo.consigneeSnPreImgsUrl[0].required = false
      this.rules.onlyOpenAccountInfo.retailerName[0].required = false
      this.rules.onlyOpenAccountInfo.retailerLicenseIssDate[0].required = false
      this.rules.onlyOpenAccountInfo.socialNo[0].required = false
      this.rules.onlyOpenAccountInfo.allLicenceDate[0].required = false
      this.rules.onlyOpenAccountInfo.dsEnterpriseAddress[0].required = false
      this.rules.onlyOpenAccountInfo.licenseUrl[0].required = false
      this.rules.managementInfo.legalPerson[0].required = false
      this.rules.managementInfo.legalPhone[0].required = false
      this.rules.managementInfo.legalEmail[0].required = false
      this.rules.managementInfo.legalIdentity[0].required = false
      this.rules.managementInfo.legalPersonDate[0].required = false
      this.rules.managementInfo.legalSnBackImgsUrl[0].required = false
      this.rules.managementInfo.legalSnPreImgsUrl[0].required = false
      this.rules.personalOpenAccountInfo.userName[0].required = false
      this.rules.personalOpenAccountInfo.email[0].required = false
      this.rules.personalOpenAccountInfo.phoneNumber[0].required = false
      this.rules.personalOpenAccountInfo.credentialNumber[0].required = false
      this.rules.personalOpenAccountInfo.personalDate[0].required = false
      this.rules.personalOpenAccountInfo.snPreImgsUrl[0].required = false
      this.rules.personalOpenAccountInfo.snBackImgsUrl[0].required = false
      this.rules.personalOpenAccountInfo.dsEnterpriseAddress[0].required = false
      this.appForm.openAccountInfo.companyName = ''
      this.appForm.openAccountInfo.socialNo = ''
      this.appForm.openAccountInfo.companyShortName = ''
      this.appForm.openAccountInfo.categoryType = ''
      this.appForm.openAccountInfo.email = ''
      this.appForm.openAccountInfo.firmScale = ''
      this.appForm.openAccountInfo.allLicenceDate = ''
      this.appForm.openAccountInfo.basicAcctNo = ''
      this.appForm.openAccountInfo.approvalNo = ''
      this.appForm.openAccountInfo.dsEnterpriseAddress = ''
      this.appForm.openAccountInfo.licenseUrl = ''
      this.appForm.companyLegalPersonInfo.legalPerson = ''
      this.appForm.companyLegalPersonInfo.legalPhone = ''
      this.appForm.companyLegalPersonInfo.legalCredentialType = ''
      this.appForm.companyLegalPersonInfo.legalIdentity = ''
      this.appForm.companyLegalPersonInfo.legalPersonDate = ''
      this.appForm.companyLegalPersonInfo.legalSnBackImgsUrl = ''
      this.appForm.companyLegalPersonInfo.legalSnPreImgsUrl = ''
      this.appForm.consigneeInfo.consigneeName = ''
      this.appForm.consigneeInfo.consigneeContactNumber = ''
      this.appForm.consigneeInfo.consigneeCredentialNumber = ''
      this.appForm.consigneeInfo.consigneeDate = ''
      this.appForm.consigneeInfo.consigneeSnBackImgsUrl = ''
      this.appForm.consigneeInfo.consigneeSnPreImgsUrl = ''
      this.appForm.onlyOpenAccountInfo.retailerName = ''
      this.appForm.onlyOpenAccountInfo.retailerLicenseIssDate = ''
      this.appForm.onlyOpenAccountInfo.socialNo = ''
      this.appForm.onlyOpenAccountInfo.allLicenceDate = ''
      this.appForm.onlyOpenAccountInfo.dsEnterpriseAddress = ''
      this.appForm.onlyOpenAccountInfo.licenseUrl = ''
      this.appForm.managementInfo.legalPerson = ''
      this.appForm.managementInfo.legalPhone = ''
      this.appForm.managementInfo.legalEmail = ''
      this.appForm.managementInfo.legalIdentity = ''
      this.appForm.managementInfo.legalPersonDate = ''
      this.appForm.managementInfo.legalSnBackImgsUrl = ''
      this.appForm.managementInfo.legalSnPreImgsUrl = ''
      this.appForm.personalOpenAccountInfo.userName = ''
      this.appForm.personalOpenAccountInfo.email = ''
      this.appForm.personalOpenAccountInfo.phoneNumber = ''
      this.appForm.personalOpenAccountInfo.credentialNumber = ''
      this.appForm.personalOpenAccountInfo.personalDate = ''
      this.appForm.personalOpenAccountInfo.snPreImgsUrl = ''
      this.appForm.personalOpenAccountInfo.snBackImgsUrl = ''
      this.appForm.personalOpenAccountInfo.dsEnterpriseAddress = ''
    },
    changeAddress (val, type) {
      if (val) {
        let code = val || []
        let province = {}
        let city = {}
        let area = {}
        this.areaList.forEach(provinceItem => {
          if (provinceItem.value === code[0]) {
            province = provinceItem
            if (!this._.isEmpty(provinceItem.children)) {
              provinceItem.children.forEach(cityItem => {
                if (cityItem.value === code[1]) {
                  city = cityItem
                  if (!this._.isEmpty(cityItem.children)) {
                    cityItem.children.forEach(areaItem => {
                      if (areaItem.value === code[2]) {
                        area = areaItem
                      }
                    })
                  }
                }
              })
            }
          }
        })
        if (type === 'openAccountInfo') {
          this.appForm.openAccountInfo.province = province.label
          this.appForm.openAccountInfo.city = city.label
          this.appForm.openAccountInfo.district = area.label
          this.appForm.openAccountInfo.provinceCode = province.value
          this.appForm.openAccountInfo.cityCode = city.value
          this.appForm.openAccountInfo.districtCode = area.value
        } else if (type === 'personalOpenAccountInfo') {
          this.appForm.personalOpenAccountInfo.province = province.label
          this.appForm.personalOpenAccountInfo.city = city.label
          this.appForm.personalOpenAccountInfo.district = area.label
          this.appForm.personalOpenAccountInfo.provinceCode = province.value
          this.appForm.personalOpenAccountInfo.cityCode = city.value
          this.appForm.personalOpenAccountInfo.districtCode = area.value
        } else if (type === 'onlyOpenAccountInfo') {
          this.appForm.onlyOpenAccountInfo.province = province.label
          this.appForm.onlyOpenAccountInfo.city = city.label
          this.appForm.onlyOpenAccountInfo.district = area.label
          this.appForm.onlyOpenAccountInfo.provinceCode = province.value
          this.appForm.onlyOpenAccountInfo.cityCode = city.value
          this.appForm.onlyOpenAccountInfo.districtCode = area.value
        } else {
          this.appForm.openAccountInfo.province = ''
          this.appForm.openAccountInfo.city = ''
          this.appForm.openAccountInfo.district = ''
          this.appForm.openAccountInfo.provinceCode = ''
          this.appForm.openAccountInfo.cityCode = ''
          this.appForm.openAccountInfo.districtCode = ''
          this.appForm.personalOpenAccountInfo.province = ''
          this.appForm.personalOpenAccountInfo.city = ''
          this.appForm.personalOpenAccountInfo.district = ''
          this.appForm.personalOpenAccountInfo.provinceCode = ''
          this.appForm.personalOpenAccountInfo.cityCode = ''
          this.appForm.personalOpenAccountInfo.districtCode = ''
          this.appForm.onlyOpenAccountInfo.province = ''
          this.appForm.onlyOpenAccountInfo.city = ''
          this.appForm.onlyOpenAccountInfo.district = ''
          this.appForm.onlyOpenAccountInfo.provinceCode = ''
          this.appForm.onlyOpenAccountInfo.cityCode = ''
          this.appForm.onlyOpenAccountInfo.districtCode = ''
        }
      } else {
        this.appForm.openAccountInfo.province = ''
        this.appForm.openAccountInfo.city = ''
        this.appForm.openAccountInfo.district = ''
        this.appForm.openAccountInfo.provinceCode = ''
        this.appForm.openAccountInfo.cityCode = ''
        this.appForm.openAccountInfo.districtCode = ''
        this.appForm.personalOpenAccountInfo.province = ''
        this.appForm.personalOpenAccountInfo.city = ''
        this.appForm.personalOpenAccountInfo.district = ''
        this.appForm.personalOpenAccountInfo.provinceCode = ''
        this.appForm.personalOpenAccountInfo.cityCode = ''
        this.appForm.personalOpenAccountInfo.districtCode = ''
        this.appForm.onlyOpenAccountInfo.province = ''
        this.appForm.onlyOpenAccountInfo.city = ''
        this.appForm.onlyOpenAccountInfo.district = ''
        this.appForm.onlyOpenAccountInfo.provinceCode = ''
        this.appForm.onlyOpenAccountInfo.cityCode = ''
        this.appForm.onlyOpenAccountInfo.districtCode = ''
      }
    },
    /**
     * 获取省市区
     */
    getAddress () {
      axios.get('/tenant/v1/mstDictInfo/selectDictInfo/area').then(res => {
        const cityData = JSON.stringify(res.data.data)
        const optionsJson = JSON.parse(cityData.replace(/code/g, 'value').replace(/name/g, 'label'))
        optionsJson.map((item, index) => {
          item.children = item.subList
          if (typeof item.subList !== 'undefined' && item.subList !== null) {
            if (item.subList.length > 0) {
              item.children.map((itemN, indexN) => {
                itemN.children = itemN.subList
              })
            }
          }
        })
        this.areaList = optionsJson
      })
    },
    /**
     * 数据封装
     */
    appFormProcessing () {
      const data = this._.cloneDeep(this.appForm)
      let info = { userType: data.userType }
      let openAccountInfo = {}
      let legalPersonInfo = {}
      let consigneeInfo = {}
      if (data.userType === '12') {
        openAccountInfo = {
          companyName: data.openAccountInfo.companyName,
          socialNo: data.openAccountInfo.socialNo,
          companyShortName: data.openAccountInfo.companyShortName,
          categoryType: data.openAccountInfo.categoryType,
          email: data.openAccountInfo.email,
          firmScale: data.openAccountInfo.firmScale,
          allLicenceIssDate: data.openAccountInfo.allLicenceDate[0],
          allLicenceExpiryDate: data.openAccountInfo.allLicenceDate[1],
          basicAcctNo: data.openAccountInfo.basicAcctNo,
          approvalNo: data.openAccountInfo.approvalNo,
          province: data.openAccountInfo.province,
          city: data.openAccountInfo.city,
          district: data.openAccountInfo.district,
          provinceCode: data.openAccountInfo.provinceCode,
          cityCode: data.openAccountInfo.cityCode,
          districtCode: data.openAccountInfo.districtCode,
          addrDetail: data.openAccountInfo.addrDetail,
          licenseUrl: data.openAccountInfo.licenseUrl
        }
        legalPersonInfo = {
          legalPerson: data.companyLegalPersonInfo.legalPerson,
          legalPhone: data.companyLegalPersonInfo.legalPhone,
          legalCredentialType: data.companyLegalPersonInfo.legalCredentialType,
          legalIdentity: data.companyLegalPersonInfo.legalIdentity,
          legalPersonIssDate: data.companyLegalPersonInfo.legalPersonDate[0],
          legalPersonExpiryDate: data.companyLegalPersonInfo.legalPersonDate[1],
          legalSnBackImgsUrl: data.companyLegalPersonInfo.legalSnBackImgsUrl,
          legalSnPreImgsUrl: data.companyLegalPersonInfo.legalSnPreImgsUrl
        }
        consigneeInfo = {
          consigneeName: data.consigneeInfo.consigneeName,
          consigneeContactNumber: data.consigneeInfo.consigneeContactNumber,
          consigneeCredentialType: data.consigneeInfo.consigneeCredentialType,
          consigneeCredentialNumber: data.consigneeInfo.consigneeCredentialNumber,
          consigneeIssDate: data.consigneeInfo.consigneeDate[0],
          consigneeExpiryDate: data.consigneeInfo.consigneeDate[1],
          consigneeSnBackImgsUrl: data.consigneeInfo.consigneeSnBackImgsUrl,
          consigneeSnPreImgsUrl: data.consigneeInfo.consigneeSnPreImgsUrl
        }
      } else if (data.userType === '11') {
        openAccountInfo = {
          userName: data.personalOpenAccountInfo.userName,
          email: data.personalOpenAccountInfo.email,
          phoneNumber: data.personalOpenAccountInfo.phoneNumber,
          credentialType: data.personalOpenAccountInfo.credentialType,
          credentialNumber: data.personalOpenAccountInfo.credentialNumber,
          issDate: data.personalOpenAccountInfo.personalDate[0],
          expiryDate: data.personalOpenAccountInfo.personalDate[1],
          snPreImgsUrl: data.personalOpenAccountInfo.snPreImgsUrl,
          snBackImgsUrl: data.personalOpenAccountInfo.snBackImgsUrl,
          province: data.personalOpenAccountInfo.province,
          provinceCode: data.personalOpenAccountInfo.provinceCode,
          city: data.personalOpenAccountInfo.city,
          cityCode: data.personalOpenAccountInfo.cityCode,
          district: data.personalOpenAccountInfo.district,
          districtCode: data.personalOpenAccountInfo.districtCode,
          addrDetail: data.personalOpenAccountInfo.addrDetail
        }
      } else if (data.userType === '13') {
        openAccountInfo = {
          retailerName: data.onlyOpenAccountInfo.retailerName,
          retailerLicenseIssDate: data.onlyOpenAccountInfo.retailerLicenseIssDate,
          socialNo: data.onlyOpenAccountInfo.socialNo,
          allLicenceIssDate: data.onlyOpenAccountInfo.allLicenceDate[0],
          allLicenceExpiryDate: data.onlyOpenAccountInfo.allLicenceDate[1],
          province: data.onlyOpenAccountInfo.province,
          city: data.onlyOpenAccountInfo.city,
          district: data.onlyOpenAccountInfo.district,
          provinceCode: data.onlyOpenAccountInfo.provinceCode,
          cityCode: data.onlyOpenAccountInfo.cityCode,
          districtCode: data.onlyOpenAccountInfo.districtCode,
          addrDetail: data.onlyOpenAccountInfo.addrDetail,
          licenseUrl: data.onlyOpenAccountInfo.licenseUrl
        }
        legalPersonInfo = {
          legalPerson: data.managementInfo.legalPerson,
          legalPhone: data.managementInfo.legalPhone,
          legalEmail: data.managementInfo.legalEmail,
          legalCredentialType: data.managementInfo.legalCredentialType,
          legalIdentity: data.managementInfo.legalIdentity,
          legalPersonIssDate: data.managementInfo.legalPersonDate[0],
          legalPersonExpiryDate: data.managementInfo.legalPersonDate[1],
          legalSnBackImgsUrl: data.managementInfo.legalSnBackImgsUrl,
          legalSnPreImgsUrl: data.managementInfo.legalSnPreImgsUrl
        }
      } else {
        openAccountInfo = {}
      }
      info.openAccountInfo = JSON.stringify(openAccountInfo)
      info.legalPersonInfo = JSON.stringify(legalPersonInfo)
      info.consigneeInfo = JSON.stringify(consigneeInfo)
      return info
    },
    /**
     * 按钮类型处理
     */
    dataProcessing (type) {
      if (type === 1) {
        this.submitForms()
      } else {
        if (this.validateFlag === 1) {
          this.$refs.appForm.clearValidate()
        }
        const data = this.appFormProcessing()
        let api = this.api.system.account.addAccount
        if (this.userId) {
          data.userId = this.userId
          data.firmId = this.appForm.firmId
          api = this.api.system.account.updateAccount
        }
        this.loading.submit = true
        api(data).then(result => {
          if (result.data.success === true) {
            this.$message.success('操作成功')
            this.loading.submit = false
          } else {
            this.loading.submit = false
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loading.submit = false
        })
      }
    },
    submitForms () {
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          const data = this.appFormProcessing()
          data.commitFlag = 1
          let api = this.api.system.account.addAccount
          if (this.userId) {
            data.userId = this.userId
            data.firmId = this.appForm.firmId
            api = this.api.system.account.updateAccount
          }
          this.loading.submit = true
          api(data).then(result => {
            if (result.data.success === true) {
              this.$message.success('操作成功')
              const data = result.data.data
              if (data.jumpbandingCardFalg) {
                this.userId = data.userId
                this.api.system.account.eJz4601(data.userId).then(result => {
                  const eJz4601 = JSON.parse(result.data.data)
                  if (eJz4601.code === '2000' && ['30', '15', '20'].includes(eJz4601.status)) {
                    this.loading.submit = false
                    this.$router.push({ name: 'waitFor', query: { userId: data.userId, firmId: data.firmId } })
                  } else {
                    this.getDetail()
                    this.loading.submit = false
                  }
                })
              } else {
                if (this.accountInfo.eaccountStatus) {
                  if (this.accountInfo.eaccountStatus === '40') {
                    this.api.system.account.eJz4601(this.userId).then(result => {
                      const eJz4601 = JSON.parse(result.data.data)
                      if (eJz4601.code === '2000' && ['30', '15', '20'].includes(eJz4601.status)) {
                        this.loading.submit = false
                        this.$router.push({ name: 'waitFor', query: { userId: this.userId, firmId: this.firmId } })
                      } else {
                        this.getDetail()
                        this.loading.submit = false
                      }
                    })
                  } else {
                    this.$router.push({ name: 'bindingBankEdit', query: { userId: data.userId, firmId: data.firmId } })
                  }
                } else {
                  this.$router.push({ name: 'bindingBankEdit', query: { userId: data.userId, firmId: data.firmId } })
                }
              }
            } else {
              this.$message.error(result.data.message)
            }
          }).catch(e => {
            this.loading.submit = false
          })
        } else {
          this.validateFlag = 1
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #454545;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
